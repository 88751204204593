import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Subscription } from "rxjs";
import { CategorySelectComponent, CategorySelectionChange } from "../../category/category-select/category-select.component";
import { TranslateService } from "@ngx-translate/core";
import { PageEvent } from "@angular/material/paginator";
import { SearchService } from "../../search/model/search.service";
import { SeriesSearchRequest, SeriesSearchResponse } from "../../search/model/search-api";

let DEFAULT_PAGE_SIZE = 25;

@Component({
  selector: 'app-series-board-content',
  templateUrl: './series-board-content.component.html',
  styleUrl: './series-board-content.component.scss'
})
export class SeriesBoardContentComponent {


  @Input() categorySelectorVisible: boolean = true

  @Output() reset = new EventEmitter<boolean>()
  @Output() requestChanged = new EventEmitter<SeriesSearchRequest>()

  searching: boolean = false
  pageSize: number = DEFAULT_PAGE_SIZE
  pageIndex: number = 0
  totalSize: number = -1

  response: SeriesSearchResponse | null = null

  request: SeriesSearchRequest = new SeriesSearchRequest([], '', this.translate.currentLang)

  private subscriptions: Subscription[] = []


  @ViewChild(CategorySelectComponent) categorySelect: CategorySelectComponent | undefined


  constructor(private searchService: SearchService,
              private translate: TranslateService
  ) {
  }

  ngOnInit() {
    let s1 = this.translate.onLangChange.subscribe(evt => {
      this.request.lang = evt.lang
      this.handleRequestChanged()
    })
    this.subscriptions = [s1]
  }

  ngAfterContentInit() {
    this.load()
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
    this.subscriptions = []
  }

  handleCategorySelectionChanged(event: CategorySelectionChange) {
    this.request.selectedApplicationId = event.selectedApplicationId
    this.request.selectedTechnologyId = event.selectedTechnologyId
    this.request.categoryIds = event.getSelectedCategoryIds()
    this.handleRequestChanged()
  }

  handlePageEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex
    this.pageSize = event.pageSize
    this.load()
  }

  resetFilter() {
    this.request = new SeriesSearchRequest([], '', this.translate.currentLang)
    this.reset.emit()
    if (this.categorySelect) this.categorySelect.reset()
    this.handleRequestChanged()
  }

  search(query: string) {
    this.request.fullTextSearch = query
    this.handleRequestChanged()
  }


  private load(overwrite: boolean = false) {
    if (this.searching && !overwrite) return
    this.searching = true

    this.searchService.searchSeries(this.request, this.pageIndex, this.pageSize)
      .subscribe(d => this.handleData(d.body))
  }

  private handleData(response: SeriesSearchResponse | null) {
    if (!response) return false
    this.response = response
    this.pageIndex = response.entries.pageable.number
    this.totalSize = response.entries.totalSize
    this.searching = false
    return true
  }

  private handleRequestChanged() {
    this.pageIndex = 0
    this.requestChanged.emit(this.request)
    this.load()
  }

  updateRequest(query: string, selectedApplicationId: string, selectedTechnologyId: string) {
    this.request.fullTextSearch = query
    this.request.selectedApplicationId = selectedApplicationId
    this.request.selectedTechnologyId = selectedTechnologyId
    this.request.categoryIds = [selectedApplicationId, selectedTechnologyId].filter(item => item.length > 0)
    if (this.categorySelect) {
      this.categorySelect.updateRequest(this.request, '')
    }
    this.load()
  }

}
