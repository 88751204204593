import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  constructor(private iconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
  }

  registerIcons(): void {
    const icons = [
      {name: 'close', path: 'assets/img/icons/close.svg'},
      {name: 'delivered', path: 'assets/img/icons/delivered.svg'},
      {name: 'delivering', path: 'assets/img/icons/delivering.svg'},
      {name: 'in_progress', path: 'assets/img/icons/in_progress.svg'},
      {name: 'partially_shipped', path: 'assets/img/icons/partially_shipped.svg'},
      {name: 'unknown', path: 'assets/img/icons/unknown.svg'}
    ];

    icons.forEach((icon) => {
      this.iconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    });
  }
}
