import {Component, input, output} from '@angular/core';
import {FilterGroupComponent} from "../filter-group/filter-group.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatIcon} from "@angular/material/icon";
import {MatMiniFabButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-filter-group-list',
  standalone: true,
  imports: [
    FilterGroupComponent,
    MatExpansionModule,
    MatIcon,
    MatMiniFabButton,
    MatTooltip
  ],
  templateUrl: './filter-group-list.component.html',
  styleUrl: './filter-group-list.component.scss'
})
export class FilterGroupListComponent {
  filterListTitle = input<string>('');
  filterListTitleSuffix = input<string>('');
  filterGroups = input<any[]>([]);
  expanded = input<boolean>(true);
  filterSelected = output<any>();
  filterCleared = output<any>();
  resetFilters = output<void>();

  onFiltersResetClick(event: MouseEvent) {
    event.stopPropagation();

    this.resetFilters.emit();
  }
}
