import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../../utils/base-service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LoggingService } from "../../../utils/logging/logging.service";
import { Observable } from "rxjs";
import { CategorySparePart, LocalizedSparePart, SparePartInfo } from "./spare-part-api";
import { TranslateService } from "@ngx-translate/core";
import { Page } from "../../../utils/page";

@Injectable({
  providedIn: 'root'
})
export class SparePartService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
    private translateService: TranslateService
  ) {
    super(http, 'api/knowledge/sparepart', logging, zone)
    this.retryCount = 1
  }

  getMediaContent(media: string): Observable<string> {
    return this.http.get('api/knowledge/sparepart/drawing/exploded/' + media, { responseType: 'text' })
  }

  getCategorySpareParts(categoryId: number): Observable<CategorySparePart[]> {
    let params = new HttpParams()
    params = params.append('lang', this.translateService.currentLang)
    return this.get('category/' + categoryId, params)
  }

  getSpareParts(page: number, size: number): Observable<Page<LocalizedSparePart>> {
    let params = new HttpParams()
      .append('lang', this.translateService.currentLang)
    return this.getPaged('', page, size, params)
  }

  getInfo(id: string): Observable<SparePartInfo> {
    let params = new HttpParams()
    params = params.append('lang', this.translateService.currentLang)
    return this.get('info/' + id, params)
  }

  getSparePartDetails(id: string): Observable<SparePartInfo> {
    let params = new HttpParams()
    params = params.append('lang', this.translateService.currentLang)
    return this.get(`${id}`, params)
  }
}
