import {Injectable} from '@angular/core';
import {CategoryService} from "../../category/model/category.service";
import {Category} from "../../category/model/category-api";
import {HotToastService} from "@ngxpert/hot-toast";
import {BehaviorSubject, forkJoin, of, switchMap} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {toSignal} from "@angular/core/rxjs-interop";
import {ProductService} from "./product.service";
import {TranslateService} from "@ngx-translate/core";
import {FilterGroup} from "../../../component/filter/filter-group/filter-group.component";
import {FilterService} from "../../../component/filter/filter.service";
import {SearchService} from "../../search/model/search.service";
import {ProductSearchRequest, ProductSearchResponse} from "../../search/model/search-api";

export interface ProductState {
  productResponse?: ProductSearchResponse;
  pageSize?: number;
  pageIndex?: number;
  totalSize?: number;
  filterGroups: FilterGroup[][];
}

export interface Categories {
  application: ExtendedCategory[];
  technology: ExtendedCategory[];
  productResponse: ProductSearchResponse | null;
}

export interface ExtendedCategory extends Category {
  amount?: number;
  category: Category;
}

export interface CategoryGroup {
  categories: ExtendedCategory[];
  name: string | undefined;
  id: string | undefined;
  selectedValue?: unknown;
}

@Injectable({
  providedIn: 'root'
})
export class ProductFacadeService {
  pageSize = 25;
  pageIndex = 0;
  totalSize = -1;
  private readonly productStateSubject = new BehaviorSubject<ProductState>({
    filterGroups: [],
    productResponse: undefined
  });
  readonly productState = toSignal<ProductState>(this.productStateSubject.asObservable());
  private readonly categoriesSubject = new BehaviorSubject<Categories>({
    application: [],
    technology: [],
    productResponse: null
  });

  private readonly categoryGroupsSubject = new BehaviorSubject<{
    application: CategoryGroup[],
    technology: CategoryGroup[]
  }>({
    application: [],
    technology: []
  });

  constructor(
    private categoryService: CategoryService,
    private searchService: SearchService,
    private translate: TranslateService,
    private toast: HotToastService,
    private filterService: FilterService
  ) {
  }

  loadRootCategories() {
    return this.categoryService.getRootCategories().pipe(
      switchMap((value) => {
        const {application, technology} = value;
        return this.handleRootData({
          applicationId: application?.id,
          technologyId: technology?.id
        });
      })
    );
  }

  clearState({value}: any) {
    value.categoryId = value.applicationId ? value.selectedApplicationId : value.selectedTechnologyId;

    if ([this.categoryGroupsSubject.value.application[0].id, this.categoryGroupsSubject.value.technology[0].id].includes(value.categoryId)) {
      return this.loadRootCategories();

    }
    value.selectedValue = null;
    return this.updateState(value);
  }

  updateState(payload: any) {
    this.setCategoryByType(payload);
    const {applicationId, selectedIds, technologyId, country, selectedValue} = payload;

    return this.handleDataUpdate(applicationId, technologyId, country, selectedIds, selectedValue);
  }

  private setCategoryByType(payload: any) {
    const currentCategory = payload.categoryId;
    if ([payload.parentGroupName, payload.groupName].includes('Application')) {
      payload.applicationId = currentCategory;
      payload.selectedIds[0] = currentCategory;
    } else {
      payload.technologyId = currentCategory;
      payload.selectedIds[1] = currentCategory;
    }
  }

  private handleRootData({applicationId, technologyId}: {
    applicationId: string | undefined,
    technologyId: string | undefined
  }, country = '') {
    const products$ = this.getProducts();
    return products$.pipe(
      switchMap((productResponse) => {
        return forkJoin({
          application: this.loadRootCategoryData(applicationId, country, productResponse),
          technology: this.loadRootCategoryData(technologyId, country, productResponse)
        }).pipe(map(data => ({...data, productResponse})));
      }),
      tap(data => {
        this.categoryGroupsSubject.next({
          application: [{
            name: 'Application',
            id: applicationId,
            categories: data.application
          }]
          ,
          technology: [{
            name: 'Technology',
            id: technologyId, categories: data.technology
          }]
        });

        this.categoriesSubject.next(data);

      }),
      map(({productResponse}) => this.mapToProductState(productResponse)),
      tap((state) => this.productStateSubject.next(state)),
      catchError((err) => this.handleRootDataError(err))
    );
  }

  private handleDataUpdate(applicationId = '', technologyId = '', country = '', selectedIds: string[], selectedValue?: any) {
    const lastCategories = this.categoriesSubject.value;

    const categoryRequests$ = [
      applicationId ? this.loadCategories(applicationId, country) : of(lastCategories.application),
      technologyId ? this.loadCategories(technologyId, country) : of(lastCategories.technology)
    ];

    return forkJoin(categoryRequests$)
    .pipe(
      switchMap((categoryData) => this.getProductDataUpdate(categoryData, selectedIds)),
      map(data => {
        this.updateFilterTree(data, applicationId, technologyId, lastCategories, selectedValue);

        return data.productResponse;
      }),
      map((productResponse) => this.mapToProductState(productResponse)),
      tap((state) => this.productStateSubject.next(state)),
      catchError((err) => this.handleRootDataError(err))
    );
  }

  private updateFilterTree(data: Categories, applicationId: string, technologyId: string, existingCategories: Categories, selectedValue: any) {
    const currentData = this.categoryGroupsSubject.value;
    let applicationGroups = currentData.application;
    let technologyGroups = currentData.technology;
    if (applicationId) {
      this.updateGroups(applicationId, applicationGroups, data.application, existingCategories.application, selectedValue);
      technologyGroups[technologyGroups.length - 1].categories = data.technology;

    } else if (technologyId) {
      this.updateGroups(technologyId, technologyGroups, data.technology, existingCategories.technology, selectedValue);
      applicationGroups[applicationGroups.length - 1].categories = data.application;
    }

    this.categoryGroupsSubject.next({
      application: applicationGroups,
      technology: technologyGroups
    });


    this.categoriesSubject.next(data);

  }

  private getGroup(id: string, categories: any[], currentCategories: ExtendedCategory[], previousGroups: CategoryGroup[]) {
    const name = currentCategories.find(cat => cat.id === id)?.title || previousGroups.flatMap(gr => gr.categories).find(c => c.id === id)?.title;
    return {
      name,
      categories,
      id
    };
  }

  private categoryInParentCategories(id: string, groups: CategoryGroup[]) {
    const idInChildCategories = groups.slice(0, -1).some(group => group.categories.some(cat => cat.id === id));
    const idInParent = groups.slice(-2, -1).some(gr => gr.id === id);
    return idInChildCategories || idInParent;
  }

  private categoryInCurrent(id: string, groups: CategoryGroup[]) {
    return groups[0].id === id;
  }

  private updateLastGroupSelectedValue(groups: CategoryGroup[], selectedValue: any) {
    const lastGroup = groups[groups.length - 1];
    lastGroup.selectedValue = selectedValue;
  }

  private clearChildGroups(id: string, groups: CategoryGroup[]) {
    for (let i = 0; i < groups.length; i++) {
      if (groups[i].categories.some(cat => cat.id === id) || groups[i].id === id) {
        groups.splice(i + 1);
        return;
      }
    }
  }

  private updateGroups(id: string, previousGroups: CategoryGroup[], nextGroups: ExtendedCategory[], currentCategories: ExtendedCategory[], selectedValue: any) {
    if (this.categoryInParentCategories(id, previousGroups)) {
      this.clearChildGroups(id, previousGroups);
      currentCategories = previousGroups.slice(-1)[0].categories;
    }

    if (this.categoryInCurrent(id, previousGroups) && !selectedValue) {
      this.clearChildGroups(id, previousGroups);
      this.updateLastGroupSelectedValue(previousGroups, selectedValue);

    }

    if (selectedValue) {
      this.updateLastGroupSelectedValue(previousGroups, selectedValue);
    }
    if (nextGroups.length && selectedValue) {
      previousGroups.push(this.getGroup(id, nextGroups, currentCategories, previousGroups));

    }
  }

  private loadRootCategoryData(categoryId: string | undefined, country = '', productResponse: ProductSearchResponse | null) {
    if (!categoryId) {
      return of([] as ExtendedCategory[]);
    }

    return this.loadCategories(categoryId, country).pipe(map((currentCategories) => this.mapExistingCategories(currentCategories, productResponse)));

  }

  private loadCategories(categoryId: string | undefined, country = '') {
    if (!categoryId) {
      return of([]);
    }

    return this.categoryService.getChildren(categoryId, country);
  }

  private getProductDataUpdate(categoryData: Category[][], categoryIds: string[] = [], technologyIds: string[] = [], seriesIds: string[] = [], fullTextSearch = '') {
    return this.getProducts(categoryIds, technologyIds, seriesIds, fullTextSearch).pipe(
      map(productResponse => ({
        application: this.mapExistingCategories(categoryData[0], productResponse),
        technology: this.mapExistingCategories(categoryData[1], productResponse),
        productResponse
      })));
  }

  private getProducts(categoryIds: string[] = [], technologyIds: string[] = [], seriesIds: string[] = [], fullTextSearch = '') {
    return this.searchService.searchProducts(this.createProductSearchRequest(categoryIds, technologyIds, seriesIds, fullTextSearch), this.pageIndex, this.pageSize)
    .pipe(map(resp => resp.body));
  }

  private handleRootDataError(err: any) {
    this.toast.error("Something went wrong", err);
    return of([]);
  }

  private mapExistingCategories(existingCategories: Category[], productResponse: ProductSearchResponse | null): ExtendedCategory[] {
    if (productResponse) {
      const productCategories = productResponse.categories;

      return existingCategories.map(existingCategory => {
        const matchedCategory = productCategories.find(productCategory => productCategory.categoryId.toString() === existingCategory.id);

        return {
          ...existingCategory,
          amount: matchedCategory?.amount,
          category: existingCategory,
        };
      });

    }
    return [];
  }

  private createProductSearchRequest(categoryIds: string[] = [], technologyIds: string[] = [], seriesIds: string[] = [], fullTextSearch = '') {
    categoryIds = categoryIds.filter(id => !!id);
    return new ProductSearchRequest(categoryIds, technologyIds, seriesIds, fullTextSearch, this.translate.currentLang);
  }

  private mapToProductState(productResponse: ProductSearchResponse | null): ProductState {
    const filterGroups = this.filterService.toFilterGroups(this.categoryGroupsSubject.value, productResponse);

    return {
      filterGroups,
      productResponse
    } as ProductState;
  }
}
