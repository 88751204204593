import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Document } from "../model/document-api";
import { PageEvent } from "@angular/material/paginator";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { AnalyticsService } from "../../../analytics.service";
import { DocumentSearchResponse, DocumentSearchResultEntry, DocumentTypeResultEntry } from "../../search/model/search-api";

@Component({
  selector: 'app-document-board-content-result-list',
  templateUrl: './document-board-content-result-list.component.html',
  styleUrl: './document-board-content-result-list.component.scss'
})
export class DocumentBoardContentResultListComponent {

  @Input()
  set response(response: DocumentSearchResponse | null) {
    this.entries = response ? response.entries.content : []
    this.pageSize = response ? response.entries.pageable.size : 25
    this.pageIndex = response ? response.entries.pageable.number : 0
    this.totalSize = response ? response.entries.totalSize : 0
    this.types = response ? response.types : []
  }

  @Input()
  set content(data: DocumentSearchResultEntry[] | Document[]) {
    this.entries = data
    this.pageSize = data.length
    this.totalSize = data.length
  }

  @Output() page = new EventEmitter<PageEvent>()

  entries: DocumentSearchResultEntry[] | Document[] = []
  pageSize: number = 25
  pageIndex: number = 0
  totalSize: number = -1
  types: DocumentTypeResultEntry[] = []
  isHandset$: Observable<boolean> = this.responsive.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private responsive: BreakpointObserver,
    private analytics: AnalyticsService
  ) {
  }

  handlePaginatorEvent(event: PageEvent) {
    this.page.emit(event)
  }

  translateType(type: string) {
    return this.types.find(d => d.type == type)?.typeName ?? type
  }


  getImage(entry: DocumentSearchResultEntry | Document) {
    switch (entry.fileType) {
      case 'pdf': {
        return 'assets/img/pdf_icon.svg'
      }
      case 'html': {
        return 'assets/img/fileicon_html.svg'
      }
      case 'zip': {
        return 'assets/img/zip-icon.svg'
      }
    }
    return ''
  }

  showDocument(entry: DocumentSearchResultEntry | Document) {
    window.open(entry.url, '_blank')
    let details = '[' + entry.code + '|' + entry.version + '|' + entry.type + ']'
    this.analytics.trackEvent("Document opened", details, 'DOCUMENT_OPENED')
  }

  protected readonly Array = Array;
}
