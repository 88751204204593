<app-filter-group-list (filterCleared)="onFilterCleared($event)"
                       (filterSelected)="onFilterSelected($event)"
                       (resetFilters)="onFiltersReset()"
                       [filterGroups]="state()!.filterGroups"
                       [filterListTitleSuffix]="filterListTitleSuffix" filterListTitle="Filters"/>
<div class="flex flex-col lg:flex-row gap-4 p-4">

  <div class="flex flex-col gap-2 flex-auto justify-start">
    <mat-progress-bar *ngIf="searching" mode="indeterminate"></mat-progress-bar>
    <ng-container *ngIf="computedResponse | async as response">
      <app-product-board-content-result-list (page)="handlePageEvent($event)"
                                             [response]="response">
      </app-product-board-content-result-list>
    </ng-container>


  </div>
</div>
