import {Component} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {map, shareReplay} from "rxjs/operators";
import {AuthService} from "./auth/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {MatSelectChange} from "@angular/material/select";
import * as moment from "moment/moment";
import {CompanyService} from "./company/model/company.service";
import {Company, CompanyValidationResult} from "./company/model/company-api";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {LoadingDialogComponent} from "./loading-dialog.component";
import {environment} from "../environments/environment";
import {Meta} from "@angular/platform-browser";
import {ConsentService} from "./consent/consent.service";
import {IconService} from "./icon.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  lang = this.translate.getBrowserLang()
  embedded: boolean = true
  company: Company | undefined
  protected readonly AuthService = AuthService;
  private dialogRef: MatDialogRef<LoadingDialogComponent> | undefined
  private subscriptions: Subscription[] = []

  constructor(
    private breakpointObserver: BreakpointObserver,
    public authService: AuthService,
    private translate: TranslateService,
    private companyService: CompanyService,
    private consentService: ConsentService,
    private dialog: MatDialog,
    private meta: Meta,
    private iconService: IconService
  ) {
    this.consentService.init()
    this.iconService.registerIcons()

    this.embedded = environment.embedded ?? false

    if (this.lang) {
      translate.setDefaultLang('en');
      translate.use(this.lang);
      moment.locale(this.lang)
    } else {
      translate.setDefaultLang('en');
      translate.use('en');
      moment.locale('en')
    }
    meta.addTag({name: "language", content: translate.currentLang})
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.dialogRef = this.dialog.open(LoadingDialogComponent, {disableClose: true});
      this.companyService.validate().subscribe(r => this.handleCompanyValidationResult(r))
    }

    let s1 = this.translate.onLangChange.subscribe(evt => {
      this.meta.updateTag({name: "language", content: evt.lang})
    })
    this.subscriptions = [s1]
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
    this.subscriptions = []
  }

  changeLang(event: MatSelectChange) {
    let value = event.value;
    this.translate.use(value)
    if (value == 'zh') value = 'zh_cn'
    moment.locale(value)
  }

  private handleCompanyValidationResult(d: CompanyValidationResult) {
    this.company = d.company
    this.dialogRef?.close()
    if (d.created) window.location.reload()
  }
}
