<mat-expansion-panel [expanded]="expanded()" hideToggle="true">
  <mat-expansion-panel-header #panelHeader (click)="panelHeader._toggle()"
                              class="panel-header">
    <mat-panel-title class="font-medium text-2xl"> {{ filterListTitle() }}&nbsp;<span
      class="opacity-60">{{ filterListTitleSuffix() }}</span>
    </mat-panel-title>
    <div>
      <button (click)="onFiltersResetClick($event)" class="mr-5" color="primary"
              mat-mini-fab matTooltip="Reset filters">
        <mat-icon>refresh</mat-icon>
      </button>
      <span (click)="panelHeader._toggle()"
            class="material-icons expansion-indicator">expand_more</span>
    </div>

  </mat-expansion-panel-header>
  @if (filterGroups().length) {
    <div class="group-container">

      @for (rootGroups of filterGroups(); track rootGroups) {
        <div class="filter-group">
          @for (group of rootGroups; track group.groupId) {

            <app-filter-group [filters]="group.filters"
                              (filterSelected)="filterSelected.emit($event)"
                              (filterCleared)="filterCleared.emit($event)"></app-filter-group>
          }
        </div>
      }

    </div>
  }
</mat-expansion-panel>
