<mat-toolbar class="gap-2 items-center">

  <mat-button-toggle-group  [(ngModel)]="service.selectedTime" (ngModelChange)="service.filterOnPreset()" class="toolbar-item">
    <mat-button-toggle value="7">{{ '7 ' + ('days' | translate) }}</mat-button-toggle>
    <mat-button-toggle value="28">{{ '28 ' + ('days' | translate) }}</mat-button-toggle>
  </mat-button-toggle-group>

  <mat-form-field appearance="outline" class="dense-1 date-range" subscriptSizing="dynamic">
    <mat-label>{{ 'timerange' | translate }}</mat-label>
    <mat-date-range-input [formGroup]="service.range"  [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="Start date">
      <input matEndDate formControlName="end" placeholder="End date">
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>


  <button mat-stroked-button
          class="toolbar-item"
          [ngClass]="{ 'selected': systemTime }"
          (click)="toggleSystemTime()"
          color="primary">
    <mat-icon>schedule</mat-icon>
    {{ 'systemTime' | translate }}
  </button>

  <div class="flex-auto"></div>
  <mat-form-field appearance="outline" subscriptSizing="dynamic" class="dense-1">
    <mat-label>{{ 'autorefresh' | translate }}</mat-label>
    <mat-select [formControl]="refreshControl" (selectionChange)="changeTimer($event)">
      @for (selection of refreshSelection; track selection) {
        <mat-option [value]="selection">
          {{ selection }} {{ 'minutes' | translate }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <button mat-mini-fab (click)="refresh()" class="toolbar-item" color="primary">
    <mat-icon>refresh</mat-icon>
  </button>
  <button mat-mini-fab class="toolbar-item" color="primary" (click)="download()">
    <mat-icon>download</mat-icon>
  </button>
</mat-toolbar>
