import { Component } from '@angular/core';
import { SparePartInfo } from "../model/spare-part-api";
import { SparePartService } from "../model/spare-part.service";
import { ProductInfo } from "../../product/model/product-api";
import { ProductService } from "../../product/model/product.service";
import { Router } from "@angular/router";
import { SparePartSearchResultEntry } from "../../search/model/search-api";

@Component({
  selector: 'app-spare-parts-board-content-details',
  templateUrl: './spare-parts-board-content-details.component.html',
  styleUrl: './spare-parts-board-content-details.component.scss'
})
export class SparePartsBoardContentDetailsComponent {

  sparePart: SparePartInfo | undefined
  content: SparePartPropertyEntry[] = []
  products: ProductInfo[] = []
  reloading: boolean = false
  productReloading: boolean = false

  constructor(private service: SparePartService, private productService: ProductService, private router: Router) {
  }


  handleSelectionChanged(entry: SparePartSearchResultEntry | undefined) {
    if (entry) {
      this.reloading = true
      this.service.getInfo(entry.id).subscribe(d => this.handleData(d))
    } else {
      this.sparePart = undefined
      this.updateProperties()
      this.updateUsage()
    }
  }

  private handleData(d: SparePartInfo) {
    this.sparePart = d
    this.updateProperties()
    this.updateUsage()
    this.reloading = false
  }

  private updateProperties() {
    if (this.sparePart) {
      this.content = [
        { text: 'spareParts.id', content: this.sparePart.id },
        { text: 'spareParts.description', content: this.sparePart.description },
        { text: 'spareParts.use', content: this.sparePart.usage },
        { text: 'spareParts.info', content: this.sparePart.information },
        { text: 'spareParts.material', content: this.sparePart.material },
        { text: 'spareParts.weight', content: this.sparePart.weight },
        { text: 'spareParts.dimension', content: this.sparePart.dimensions }
      ]
    } else {
      this.content = []
    }
  }

  private updateUsage() {
    if (this.sparePart) {
      this.productReloading = true
      this.productService.findBySparePart(this.sparePart.id).subscribe(d => this.handleProductData(d))
    } else {
      this.productReloading = false
      this.products = []
    }
  }


  private handleProductData(d: ProductInfo[]) {
    this.products = d
    this.productReloading = false
  }

  showProduct(product: ProductInfo) {
    this.router.navigate(['/portal/product/details'], { queryParams: { product: product.id } }).then()
  }
}

interface SparePartPropertyEntry {
  text: string
  content: any
}
