import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Subscription } from "rxjs";
import { ActivatedRoute, NavigationExtras, ParamMap, Router } from "@angular/router";
import { ProductBoardContentComponent } from "../product-board-content/product-board-content.component";
import { HomeService } from "../../../home/model/home.service";
import { ProductSearchRequest } from "../../search/model/search-api";


@Component({
  selector: 'app-product-board',
  templateUrl: './product-board.component.html',
  styleUrls: ['./product-board.component.scss']
})
export class ProductBoardComponent {

  private subscriptions: Subscription[] = []
  @ViewChild(ProductBoardContentComponent) content: ProductBoardContentComponent | undefined

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    private home: HomeService,
  ) {
  }

  ngOnInit() {
    this.home.searchVisible = true
  }

  ngAfterViewInit() {
    let s2 = this.route
      .queryParamMap
      .subscribe(value => this.handleUrlParamsChanged(value))

    let s3 = this.home.search.subscribe(query => {
        if (this.content) this.content.search(query)
      }
    )

    this.subscriptions = [s2, s3]
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
    this.subscriptions = []

    this.home.customHeaderActions = []
    this.home.searchVisible = false
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges()
  }


  private handleUrlParamsChanged(value: ParamMap) {
    let query = value.get('q') ?? ""
    let technologyIds: string[] = value.get('t')?.split(',') ?? []
    let ca = value.get('ca') ?? ''
    let ct = value.get('ct') ?? ''
    let seriesIds: string[] = value.get('s')?.split(',') ?? []

    if (this.content) this.content.updateRequest(query, technologyIds, ca, ct, seriesIds)
  }

  protected handleRequestChanged(request: ProductSearchRequest) {
    let query = (request.fullTextSearch.length > 0) ? request.fullTextSearch : undefined
    const navigationExtras: NavigationExtras = {
      queryParams: {
        q: query,
        t: (request.technologyIds.length) ? request.technologyIds : undefined,
        ca: (request.selectedApplicationId.length > 0) ? request.selectedApplicationId : undefined,
        ct: (request.selectedTechnologyId.length > 0) ? request.selectedTechnologyId : undefined,
        s: (request.seriesIds.length) ? request.seriesIds : undefined,
      },
      relativeTo: this.route
    }
    this.home.query = request.fullTextSearch
  }

}
